"use client"

import axios from "axios"
import * as Yup from "yup"
import { TextField } from "formik-mui"
import { Field } from "formik"
import { Formik } from "formik"
import { useState, useCallback, useEffect } from "react"

import Image from "next/image"
import { useReCaptcha } from "next-recaptcha-v3"
import { useSession, signIn } from "next-auth/react"

import Container from "../Container/Container"
import Row from "../Row/Row"
import Col from "../Col/Col"
import Spacer from "../Spacer/Spacer"
import RegularButton from "../RegularButton/RegularButton"
import styles from "./JoinSubmit.module.scss"
import { mergeUrl, getFormId } from "@/utils/merge"
import { setUtmsToFormData } from "@/utils/utms"
import LinkedinIcon from "@/components/Icons/linkedin"
import enText from "@/data/global/en"

export default function JoinSubmit({
  texts = enText.form,
  isOnSide = false,
  withRadio = true,
}) {
  const session = useSession()

  let initialValues = session?.data?.user
    ? { ...session.data.user, exclude_from_maillists: false }
    : {
        first_name: "",
        email: "",
        exclude_from_maillists: false,
      }

  const [errorMessage, setErrorMessage] = useState("")

  const [isSuccess, setIsSuccess] = useState(false)
  const { executeRecaptcha } = useReCaptcha()

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("E-mail must be valid")
      .required("E-mail is required"),
  })

  const items = [
    "Be the first to hear about future events and receive your VIP invite",
    "Have priority access to videos and highlights from each event",
    "Receive monthly market updates from our experts",
  ]

  useEffect(() => {
    if (session?.status === "authenticated") {
      initialValues = { ...session.data.user, exclude_from_maillists: false }
    }
  }, [session])

  function backToForm() {
    setIsSuccess(false)
    setErrorMessage("")
    // formik.resetForm()
  }

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      const token = await executeRecaptcha("lead")
      setErrorMessage("")

      const formData = new FormData()
      setUtmsToFormData(formData)

      for (const [key, value] of Object.entries(values)) {
        if (key === "email") {
          formData.set(key, value.toLowerCase())
        } else if (key === "exclude_from_maillists") {
          formData.set(key, !value)
        } else {
          formData.set(key, value)
        }
      }

      if (formData.get("company_name") === "") {
        formData.delete("company_name")
      }

      // formData.set('recaptcha', recaptchaToken)
      formData.set("form_id", getFormId())
      formData.set("recaptcha", token)

      await axios
        .post(mergeUrl(), formData)
        .then(({ data }) => {
          // this.loading = false
          setIsSuccess(true)
          resetForm()
          // formik.resetForm()
        })
        .catch((error) => {
          if (error.response.data.email) {
            setErrorMessage(
              error.response.data.email[0] || "Something went wrong.."
            )
          } else {
            setErrorMessage(error.response.data.detail)
          }
        })
    },
    [executeRecaptcha]
  )

  const FormSuccess = () => {
    return (
      <div className={styles.join__success}>
        <h3 className={styles.join__successtitle}>
          Thank you for registering!
        </h3>
        {/* <p className={styles.join__successtext}>
        Thank you for joining!
      </p> */}
        <button className={styles.join__successbutton} onClick={backToForm}>
          Back to form
        </button>
      </div>
    )
  }

  const FormikForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form id="form" className={styles.join__form} onSubmit={handleSubmit}>
            <Row>
              {session?.status !== "authenticated" && (
                <Col cols="24">
                  <RegularButton
                    className={`${styles.join__formbtn} ${styles.join__formbtn_linkedin}`}
                    btnStyle="secondaryWhite"
                    text={"AutoFill with LinkedIn"}
                    onClick={() =>
                      signIn("linkedin", { callbackUrl: "/#join" })
                    }
                    pre_text={<LinkedinIcon />}
                  />
                </Col>
              )}
              <Col
                cols="12"
                md="12"
                lg={isOnSide ? 24 : 12}
                xl={11.5}
                className={styles.join__input}
              >
                <Field
                  component={TextField}
                  name="first_name"
                  type="text"
                  label="Name"
                  variant="standard"
                  value={values.first_name || ""}
                />
              </Col>
              <Spacer />
              <Col
                className={styles.join__input}
                cols="12"
                md="12"
                lg={isOnSide ? 24 : 12}
                xl={11.5}
              >
                <Field
                  component={TextField}
                  name="last_name"
                  type="text"
                  label="Surname"
                  variant="standard"
                  value={values.last_name || ""}
                />
              </Col>
              <Col
                cols="12"
                md="12"
                lg={isOnSide ? 24 : 12}
                xl={11.5}
                className={styles.join__input}
              >
                <Field
                  component={TextField}
                  name="company_name"
                  type="text"
                  label="Company"
                  variant="standard"
                  value={values.company_name || ""}
                />
              </Col>
              <Spacer />

              <Col
                cols="12"
                md="12"
                lg={isOnSide ? 24 : 12}
                xl={11.5}
                className={styles.join__input}
              >
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  variant="standard"
                  value={values.email || ""}
                />
              </Col>
            </Row>
            <Row>
              <Col cols="24">
                {withRadio && (
                  <label className={styles.join__radio}>
                    <Field type="checkbox" name="exclude_from_maillists" />
                    <span></span>
                    <p
                      dangerouslySetInnerHTML={{ __html: texts.marketingCom }}
                    ></p>
                  </label>
                )}
              </Col>
            </Row>
            <Row className={styles.join__formfooter}>
              <Col
                cols="12"
                md="12"
                lg={isOnSide ? 24 : 12}
                xl={11}
                className={styles.join__input}
              >
                <RegularButton
                  className={styles.join__formbtn}
                  btnStyle="white"
                  type="submit"
                  text={"Join the horizon club"}
                />
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    )
  }

  return (
    <section
      className={`${styles.join} ${isOnSide ? styles.join_side : ""}`}
      id="join"
    >
      <Container className={styles.join__container}>
        <Row>
          <Col cols="24">
            <h2>Sign up and be a&nbsp;Horizon VIP</h2>
          </Col>
          <Col cols="24" lg={isOnSide ? 24 : 11}>
            {errorMessage && (
              <div id="signup_error" className={styles.join__error}>
                {errorMessage}
              </div>
            )}
            {isSuccess ? (
              <FormSuccess></FormSuccess>
            ) : (
              <>
                <FormikForm></FormikForm>
              </>
            )}
          </Col>
          <Spacer />
          <Col cols="24" lg="12" className={styles.join__listcontainer}>
            <ul className={styles.join__list}>
              {items.map((item, index) => {
                return (
                  <li key={index} className={styles.join__item}>
                    <Image
                      className={styles.join__tick}
                      src="/images/tick.svg"
                      width={40}
                      height={40}
                      alt="join tick"
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item,
                      }}
                    ></p>
                  </li>
                )
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
